import { LocationQuery } from 'vue-router'

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const userStore = useUserStore()

    let userGuid =
      typeof to.query.userguid === 'string' ? to.query.userguid : ''
    if (userGuid === '') {
      // fallback for old query param until backend is updated
      userGuid = typeof to.query.guid === 'string' ? to.query.guid : ''
    }
    const magic = typeof to.query.magic === 'string' ? to.query.magic : ''

    if (userGuid && magic) {
      await userStore.loginMagic(userGuid, encodeURIComponent(magic))

      const newQuery: LocationQuery = { ...to.query }
      delete newQuery['magic']

      return navigateTo({
        query: newQuery,
      })
    }
  }
})
