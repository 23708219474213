import type { Subscription } from '~/typesAuto/apicore/v1'
import type { CampaignDto } from '~/typesAuto/apicore/v1'

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async createSubscription(clientId: number, data: Record<string, unknown>) {
      return await apiCoreFetch<Subscription>(
        `clients/${clientId}/subscriptions`,
        {
          method: 'POST',
          body: data,
        }
      )
    },
    async list(clientId: number) {
      return await apiCoreFetch<Subscription[]>(
        `clients/${clientId}/subscriptions`
      )
    },
    async campaignList() {
      return await apiCoreFetch<CampaignDto[]>(
        `/v1/Subscriptions/iteras/campaigns`
      )
    },
    async campaign(campaignId: string) {
      return await apiCoreFetch<CampaignDto>(
        `/v1/Subscriptions/iteras/campaigns/${campaignId}`
      )
    },
  }
}
