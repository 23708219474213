import { LocationQuery } from 'vue-router'

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const confirmemailtoken =
      typeof to.query.confirmemailtoken === 'string'
        ? to.query.confirmemailtoken
        : ''

    const receiptPapers =
      typeof to.query.receiptPapers === 'string' ? to.query.receiptPapers : ''
    const queryErr = typeof to.query.err === 'string' ? to.query.err : ''

    if (!confirmemailtoken || queryErr) {
      return
    }

    const { confirmEmailJwt, confirmEmail, sendAccessGrantedReceiptEmail } =
      useEmailFlow()
    const { sentry } = useSentry()
    const dataLayer = useDataLayer()
    let userGuid =
      typeof to.query.userguid === 'string' ? to.query.userguid : ''
    if (userGuid === '') {
      // fallback for old query param until backend is updated
      userGuid = typeof to.query.guid === 'string' ? to.query.guid : ''
    }

    const userStore = useUserStore()

    const newQuery: LocationQuery = { ...to.query }
    // JWT
    if (userGuid) {
      const { err } = await confirmEmailJwt(confirmemailtoken, userGuid)
      if (err) {
        console.error(err.cause)
        if (sentry) {
          sentry.captureException(err)
        }

        newQuery['err'] = 'confirmtoken'
        return navigateTo({
          query: newQuery,
        })
      }

      newQuery['emailconfirmed'] = 'true'
      delete newQuery['confirmemailtoken']
      dataLayer.trial.activateUser()

      if (userStore.user?.userId && receiptPapers) {
        const { err } = await sendAccessGrantedReceiptEmail(
          userStore.user.userId,
          receiptPapers
        )
        if (err) {
          console.error(err.cause)
          if (sentry) {
            sentry.captureException(err)
          }

          newQuery['err'] = 'sendreceipt'
          return navigateTo({
            query: newQuery,
          })
        }
      }
      await userStore.refreshUser()

      return navigateTo({
        query: newQuery,
      })
    }

    // AUTOLOGIN
    const { err, data: user } = await confirmEmail(confirmemailtoken) // NOTH CONFIRMS AND LOGS IN THE USER
    if (err) {
      console.error(err.cause)
      if (sentry) {
        sentry.captureException(err)
      }

      newQuery['err'] = 'confirmtoken'
      return navigateTo({
        query: newQuery,
      })
    }

    newQuery['emailconfirmed'] = 'true'
    delete newQuery['confirmemailtoken']

    if (userStore.isLoggedIn && userStore.user) {
      await userStore.loadUserFromAutologin({
        autologin: userStore.user.legacyAutoToken,
      })
    }

    dataLayer.trial.activateUser()

    if (user && receiptPapers) {
      const { err } = await sendAccessGrantedReceiptEmail(
        user.id,
        receiptPapers
      )
      if (err) {
        console.error(err.cause)
        if (sentry) {
          sentry.captureException(err)
        }

        newQuery['err'] = 'sendreceipt'
        return navigateTo({
          query: newQuery,
        })
      }

      delete newQuery['receiptPapers']
    }

    return navigateTo({
      query: newQuery,
    })
  }
})
