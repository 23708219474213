export default defineNuxtRouteMiddleware(async (to) => {
  const autoLogin = useLegacyAutoLogin()
  if (!autoLogin.token.value) {
    return
  }

  const userStore = useUserStore()
  if (userStore.isLoggedIn) {
    return
  }

  await userStore
    .loadUserFromAutologin({
      autologin: autoLogin.token.value,
    })
    .catch(async (error) => {
      if (error.response && error.response.status === 401) {
        // clear the cookie if the autologin fails
        await userStore.logout()
        // return navigateTo({
        //   name: to.name,
        // })
      } else {
        throw error
      }
    })
})
