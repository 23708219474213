export type PaymentMethod = {
  brands?: string[]
  name: string
  type: string
  issuers?: Issuer[]
}

export type Issuer = {
  id: string
  name: string
  disabled?: boolean
}
export type StoredPaymentMethod = {
  brand: string
  expiryMonth: string
  expiryYear: string
  holderName: string
  id: string
  lastFour: string
  name: string
  networkTxReference: string
  supportedShopperInteractions: string[]
  type: string
}

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async list(clientId: number) {
      return await apiCoreFetch<{
        paymentMethods: PaymentMethod[]
        storedPaymentMethods: StoredPaymentMethod[]
      }>(`clients/${clientId}/paymentMethods`)
    },
  }
}
