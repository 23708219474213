import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  // There is a bug with Vercel + Nuxt 3, which causes problems with special characters in query parameters.
  // https://github.com/nuxt/nuxt/issues/14316#issuecomment-1397355124
  stringifyQuery: (query) => {
    const keys = Object.keys(query)
    return keys
      .map((key) => `${key}=${encodeURIComponent(query[key] as string)}`)
      .join('&')
  },
}
