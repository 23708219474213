<template>
  <button
    class="border whitespace-nowrap inline-flex items-center transition-colors duration-100 select-none shrink-0 justify-center rounded-full"
    :disabled
    :class="[
      { 'cursor-pointer': !disabled },
      { 'disabled:opacity-50 disabled:cursor-not-allowed': disabled },
      size,
      border,
      bg,
      bgHover,
      textHover,
      sizeClass,
      text,
    ]"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    size?: string
    border?: string
    bg?: string
    bgHover?: string
    text?: string
    textHover?: string
    disabled?: boolean
  }>(),
  {
    size: 'large',
    border: 'border-gray-300',
    bg: 'bg-white',
    bgHover: 'hover:bg-gray-300',
    text: 'text-gray-700',
    textHover: 'hover:text-gray-900',
    disabled: false,
  }
)

const sizeClass = computed(() => {
  const classString =
    props.size === 'medium'
      ? 'button-m px-3'
      : props.size === 'small'
      ? 'button-s px-2'
      : 'button-l px-4'

  return classString
})
</script>

<style lang="scss" scoped>
.small {
  height: 24px;
}
.medium {
  height: 30px;
}
.large {
  height: 36px;
}
.xlarge {
  height: 42px;
}
.xxlarge {
  height: 52px;
}
</style>
