import { useLocalStorage } from '@vueuse/core'

export function useLocaleOverride() {
  const route = useRoute()
  const localeOverride = route.query?.localeOverride?.toString()
  let localeState
  // can't use localstorage in incognito in an iframe. Fall back to usestate.
  // useState will work, because it's not really possible to navigate in iframe
  try {
    localeState = useLocalStorage<string | undefined>(
      'localeOverride',
      localeOverride ?? ''
    )
  } catch {
    localeState = useState<string | undefined>('localeState')
  }
  if (localeOverride) {
    localeState.value = localeOverride
  }
  return localeState
}
