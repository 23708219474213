import { defu } from 'defu'

export const useSiteConfig = () => {
  const baseConfig = useRuntimeConfig().public.site

  // Get any currently set runtime override
  const runtimeOverride = useRuntimeOverride()

  if (!runtimeOverride.value) return baseConfig

  // Merge the runtime override with the base config
  return defu(runtimeOverride.value, baseConfig)
}
