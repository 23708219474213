import type { TrackingEventDTO } from '~/typesAuto/apicore/v1'
export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async pageView(data: Record<string, unknown>) {
      await apiCoreFetch('cosmos/pageView', {
        method: 'POST',
        body: data,
      })
    },
    async trialSignup(data: TrackingEventDTO) {
      await apiCoreFetch('cosmos/trialSignup', {
        method: 'POST',
        body: data,
      })
    },
  }
}
