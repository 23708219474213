import { ApiResponse, ApiDataResponse } from '~/typesManual/apiResponse'

export const createSuccessResponse = (): ApiResponse => {
  return { err: undefined }
}

export const createErrorResponse = (
  message: string,
  cause?: unknown
): ApiResponse => {
  return {
    err: new Error(message, {
      cause: cause,
    }),
  }
}

export const createSuccessDataResponse = <T>(data: T): ApiDataResponse<T> => {
  return { err: undefined, data: data }
}

export const createErrorDataResponse = <T>(
  message: string,
  cause?: unknown
): ApiDataResponse<T> => {
  return {
    err: new Error(message, {
      cause: cause,
    }),
    data: undefined,
  }
}
