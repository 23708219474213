export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig()
  // If path contains three slashes it's probably a redirect gone wrong. Same if it contains altinget.se a lot of times.
  // In this case we want to kill the request, to prevent an infinite loop
  if (
    to.fullPath.includes('///') ||
    to.fullPath.split(config.public.site.domainfull).length > 4
  ) {
    return abortNavigation(
      `Possible infinite redirect detected. Aborting request. to.fullPath: ${to.fullPath}. from.fullPath: ${from.fullPath}`
    )
  }
})
