export default defineNuxtPlugin({
  name: 'intlFormatter',
  setup: (nuxtApp) => {
    const localeCode = nuxtApp.$config.public.site?.localeCode || 'da-DK'
    const currency = nuxtApp.$config.public.site?.currency || 'DKK'

    const numberFormatter = new Intl.NumberFormat(localeCode)

    const currencyFormatterNoDecimals = new Intl.NumberFormat(localeCode, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
    })
    const currencyFormatterTwoDecimals = new Intl.NumberFormat(localeCode, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    })

    const listAndFormatter = new Intl.ListFormat(localeCode, {
      type: 'conjunction',
    })
    const listOrFormatter = new Intl.ListFormat(localeCode, {
      type: 'disjunction',
    })

    return {
      provide: {
        formatNumber: (number) => numberFormatter.format(number),
        formatCurrency: (number) =>
          Number.isInteger(number)
            ? currencyFormatterNoDecimals.format(number)
            : currencyFormatterTwoDecimals.format(number),

        formatDate: (date) =>
          new Date(date).toLocaleDateString(localeCode, {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
          }),
        formatList: (list, type = 'conjunction') =>
          type === 'conjunction'
            ? listAndFormatter.format(list)
            : listOrFormatter.format(list),
      },
    }
  },
})
