import { PaymentDetailResponse } from '~/typesAuto/apicore/v1'

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async create(clientId: number, data: Record<string, unknown>) {
      return await apiCoreFetch<PaymentDetailResponse>(
        `clients/${clientId}/payments`,
        {
          method: 'POST',
          body: data,
        }
      )
    },
  }
}
