<template>
  <div id="app" class="body-m">
    <div id="page" class="container text-center p-20 md:mt-8">
      <CommonError :title="title ?? ''" :description="description">
        <template #top>
          <PaperLogo />
        </template>
        <template #bottom>
          <div>
            <NuxtLink
              :to="{
                name: 'frontpage',
              }"
            >
              <CommonButton bg="bg-blue" text="text-white">
                {{ localizedErrorTexts.backToHome }}
              </CommonButton>
            </NuxtLink>
          </div>
        </template>
      </CommonError>
    </div>
    <AlrowTools />
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()

const props = defineProps<{
  error: {
    statusCode?: number
    message?: string
  }
}>()
console.log('Error', props.error)

// Hardcoded translations so nuxtApp isn't needed
const errorTexts = {
  da: {
    backToHome: 'Tilbage til forsiden',
    pageNotFound: {
      title: 'Siden kunne ikke findes',
      description:
        'Du har muligvis indtastet adressen forkert, eller siden kan være flyttet. Det kan også skyldes, at der er en fejl i det link, du har klikket på, eller at du har skrevet forkert i adressefeltet.',
    },
    somethingWentWrong: {
      title: 'Noget gik galt',
      description: 'Prøv igen senere.',
    },
    articleNotFound: {
      title: 'Artiklen blev ikke fundet',
      description:
        'Artiklen du leder efter findes ikke eller er blevet flyttet. Det kan også skyldes, at der er en fejl i det link, du har klikket på, eller at du har skrevet forkert i adressefeltet.',
    },
    personNotFound: {
      title: 'Personen blev ikke fundet',
    },
  },
  se: {
    backToHome: 'Tillbaka till startsidan',
    pageNotFound: {
      title: 'Sidan hittas inte',
      description:
        'Du kan ha skrivit in adressen felaktigt eller så kan sidan ha flyttats. Det kan också bero på att det är fel i länken du har klickat på eller att du har skrivit fel i adressfältet.',
    },
    somethingWentWrong: {
      title: 'Något gick fel',
      description: 'Vänligen försök igen senare.',
    },
    articleNotFound: {
      title: 'Artikeln hittas inte',
      description:
        'Artikeln du söker finns inte eller så har den flyttats. Det kan också bero på att det är fel i länken du har klickat på eller att du har skrivit fel i adressfältet.',
    },
    personNotFound: {
      title: 'Personen hittas inte',
    },
  },
  no: {
    backToHome: 'Tilbake til forsiden',
    pageNotFound: {
      title: 'Siden kunne ikke finnes',
      description:
        'Du har muligens tastet inn adressen feil, eller siden kan ha blitt flyttet. Det kan også skyldes en feil i lenken du klikket på, eller at du skrev feil i adressefeltet.',
    },
    somethingWentWrong: {
      title: 'Noe gikk galt',
      description: 'Prøv igjen senere.',
    },
    articleNotFound: {
      title: 'Artikkelen ble ikke funnet',
      description:
        'Artikkelen du leter etter finnes ikke eller er blitt flyttet. Det kan også skyldes en feil i lenken du klikket på, eller at du skrev feil i adressefeltet.',
    },
    personNotFound: {
      title: 'Personen ble ikke funnet',
    },
  },
}

const locale = computed(() => config.public.site?.locale ?? 'da')

const localizedErrorTexts = computed(() => errorTexts[locale.value])

const title = computed(() => {
  switch (props.error?.statusCode) {
    case 0:
      return props.error.message

    case 404:
      return localizedErrorTexts.value.pageNotFound.title

    case 500:
      return localizedErrorTexts.value.somethingWentWrong.title

    default:
      return props.error.message
  }
})

const description = computed(() => {
  switch (props.error?.statusCode) {
    case 404:
      return localizedErrorTexts.value.pageNotFound.description

    case 500:
    default:
      return localizedErrorTexts.value.somethingWentWrong.description
  }
})

useHead({
  title: title.value,
})
</script>
