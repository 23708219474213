import type { Paper } from '~/typesAuto/apicore/v1'

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async list() {
      return await apiCoreFetch<Paper[]>('papers')
    },
  }
}
