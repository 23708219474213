import { nanoid } from 'nanoid'
import { menu, papers } from '#static-data'
import type { ContentPaper } from '~/typesManual/content_api/paper'
import type { Menu } from '~/typesManual/menu'
import type { WeeklyTheme } from '~/typesManual/content_api/weekly_theme'

export const useIndexStore = defineStore('index', {
  state: () => ({
    papers: papers as ContentPaper[], // static-data module can't import types yet
    menu: menu as Menu, // static-data module can't import types yet // TODO static-data module should use the correct type
    currentPaper: undefined as ContentPaper | undefined,
    weeklyTheme: undefined as undefined | WeeklyTheme,
    weeklyThemeColor: undefined as undefined | string,
    skeleton: {
      primaryColor: 'rgba(0,0,0,0.05)',
      secondaryColor: 'rgba(0,0,0,0.1)',
    },
    modalOptions: {
      maxHeight: 90,
      maxWidth: 720,
      adaptive: true,
      width: '90%',
      height: 'auto',
      transition: 'modal',
    },
    sessionId: nanoid(),
    debugAds: false,
  }),

  getters: {
    // Dont show paper.RecordId = 1, since its the frontpage paper
    // Only have active papers, which have paper.Status !== 0
    activePapers: (state) => {
      return state.papers.filter(
        (paper) => paper.RecordId !== 1 && paper.Status !== 0
      )
    },
    currentPaperUrl: (state) => {
      return state.currentPaper?.RecordId === 1
        ? '/'
        : `/${state.currentPaper?.Url}/`
    },
    currentPaperSlug: (state) => state.currentPaper?.Url || undefined,
  },

  actions: {
    setCurrentPaper(payload: string) {
      if (this.papers && this.papers.length > 0) {
        if (payload) {
          this.currentPaper = this.papers.find((x) => x.Url === payload)
        } else if (this.papers[0].RecordId === 1) {
          this.currentPaper = this.papers[0]
        } else {
          this.currentPaper = {
            RecordId: 1,
          }
        }
      }
    },
    setWeeklyThemeColor(payload: string) {
      this.weeklyThemeColor = payload
    },
    setWeeklyTheme(payload: WeeklyTheme) {
      this.weeklyTheme = payload
    },
  },
})
