import { useLocalStorage } from '@vueuse/core'
import type { Election } from '~/typesManual/vaa_api/election'
import type { AnswersCookie } from '~/typesManual/vaa_api/valgomat'
import type {
  BigConstituency,
  Municipality,
  Region,
  SmallConstituency,
} from '~/typesManual/vaa_api/areas'
import defaultsDeep from 'lodash/defaultsDeep'
import vaaDefaults from '~/config/vaaDefaults'

import type { VaaConfig } from '~/typesManual/config/vaa'

export type UserMeta = {
  groupId?: number
  bigConstituency?: BigConstituency
  smallConstituency?: SmallConstituency
  municipality?: Municipality
  region?: Region
}

const VAA_PREFIX = 'vaa'
const USERMETA = 'usermeta'
const ANSWERS = 'answers'
const EXTRA_TOPIC_IDS = 'extraTopicIds'

function getAnswersKey(election?: Election) {
  return `${election?.ID}-${election?.valgomat?.id}`
}
export function useVaa() {
  const userMetaKey = `${VAA_PREFIX}:${USERMETA}`
  const vaaStorageAnswersKey = `${VAA_PREFIX}:${ANSWERS}`
  const extraTopicIdsKey = `${VAA_PREFIX}:${EXTRA_TOPIC_IDS}`

  let usermeta
  let vaaAnswers
  let extraTopicIds
  try {
    usermeta = useLocalStorage<UserMeta | undefined>(userMetaKey, {})

    vaaAnswers = useLocalStorage<Record<string, AnswersCookie>>(
      vaaStorageAnswersKey,
      {}
    )

    extraTopicIds = useLocalStorage<Record<string, number[]>>(
      extraTopicIdsKey,
      {}
    )
  } catch {
    usermeta = useState<UserMeta>('usermeta')
    vaaAnswers = useState<Record<string, AnswersCookie>>('vaaAnswers', () => {
      return {}
    })
    extraTopicIds = useState<Record<string, number[]>>('extraTopicIds', () => {
      return {}
    })
  }

  return {
    usermeta,
    vaaAnswers,
    extraTopicIds,
    getAnswersKey,
  }
}

export function useVaaConfig() {
  const siteConfig = useSiteConfig()

  return siteConfig.vaaConfig
}

export function useVaaLookup() {
  const siteConfig = useSiteConfig()

  const vaaLookup = siteConfig.vaa
    ? Object.fromEntries(
        Object.entries(siteConfig.vaa).map(([key, value]) => [
          key,
          defaultsDeep(value, vaaDefaults) as VaaConfig,
        ])
      )
    : undefined

  return vaaLookup
}
