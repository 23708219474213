import type {
  PaymentDetailResponse,
  PaymentSessionRequest,
  PaymentSessionResponse,
  PaymentTransaction,
} from '~/typesAuto/apicore/v1'

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async create(clientId: number, data: PaymentSessionRequest) {
      return await apiCoreFetch<PaymentSessionResponse>(
        `clients/${clientId}/paymentSessions`,
        { method: 'POST', body: data }
      )
    },
    async get(sessionId: number) {
      return await apiCoreFetch(`paymentSessions/${sessionId}`)
    },
    async getTransactionFromPspReference(transactionId: string) {
      return await apiCoreFetch<PaymentTransaction>(
        `paymentSessions/transaction/${transactionId}`
      )
    },
    async getTransactionFromSessionId(sessionId: string) {
      return await apiCoreFetch<PaymentTransaction>(
        `paymentSessions/transaction/sessionId/${sessionId}`
      )
    },
    async checkResult(redirectResult: string) {
      return await apiCoreFetch<PaymentDetailResponse>(`payment/checkResult`, {
        method: 'POST',
        body: { redirectResult },
      })
    },
    async deleteCard(clientId: number, recurringDetailReference: string) {
      return await apiCoreFetch(
        `clients/${clientId}/paymentMethods/${recurringDetailReference}`,
        { method: 'DELETE' }
      )
    },
  }
}
