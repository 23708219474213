// lwhb is the object from AdProfit. This mounts to be used in the Vue/Nuxt context

export default defineNuxtPlugin({
  name: 'adProfit',
  setup: (nuxtApp) => {
    return {
      provide: {
        lwhb: nuxtApp.$lwhb || { cmd: [] },
      },
    }
  },
})
