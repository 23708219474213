// PROJECT: striphtml

// Inject $hello(msg) in Vue, context and store.

export default defineNuxtPlugin({
  name: 'striphtml',
  setup: () => {
    const regex = /(<([^>]+)>)/gi
    return {
      provide: {
        striphtml: (msg) => msg.replace(regex, ''),
      },
    }
  },
})
