import type { ArticleDTO, ArticleTypeGroup } from '~/typesAuto/apicore/v1'

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async getSponsoredArticles({
      paperId,
      $top,
      offset,
    }: {
      paperId: number
      $top?: number
      offset?: number
    }) {
      const sponsoredContentTeasers = await apiCoreFetch<{
        data: ArticleDTO[]
      }>('SponsoredContentTeasers', { query: { paperId, $top } })

      return {
        ...sponsoredContentTeasers,
        data: sponsoredContentTeasers.data.slice(offset, $top),
      }
    },
    async getArticlesByGroup({
      paperId,
      pageSize,
      pageNumber,
      typeGroup,
    }: {
      paperId: number
      pageSize: number
      pageNumber?: number
      typeGroup?: ArticleTypeGroup
    }) {
      return await apiCoreFetch<{
        data: ArticleDTO[]
        totalPages: number
      }>(`articles`, {
        query: {
          typeGroup,
          paperId,
          pageSize,
          pageNumber,
        },
      })
    },
  }
}
