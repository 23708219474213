import type {
  ConferenceDTO,
  CourseDTO,
  ParticipantSubmitDTO,
} from '~/typesAuto/apicore/v1'
import type { Network } from '~/typesAuto/apicore/v2'

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async getCourse(courseId: string) {
      return await apiCoreFetch<CourseDTO>(`Courses/${courseId}`)
    },
    async getCourses() {
      return await apiCoreFetch<CourseDTO[]>('Courses')
    },
    async getNetwork(networkId: string) {
      return await apiCoreFetch<Network>(`/v2/Networks/${networkId}`)
    },
    async getNetworks() {
      return await apiCoreFetch<Network[]>('/v2/Networks')
    },
    async getConference(conferenceId: string) {
      return await apiCoreFetch<ConferenceDTO>(`Conferences/${conferenceId}`)
    },
    async getConferences() {
      return await apiCoreFetch<ConferenceDTO[]>('Conferences')
    },
    async submitParticipant(
      participant: ParticipantSubmitDTO,
      eventId: number
    ): Promise<unknown> {
      return await apiCoreFetch(`EventTeams/${eventId}/participant`, {
        method: 'POST',
        body: participant,
      })
    },
  }
}
