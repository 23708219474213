import type { Lead, TrialSignUpLead } from '~/typesAuto/apicore/v1'

export default () => {
  const { apiCoreFetch } = useApiFetch()

  return {
    async submitAdsLead(data: Lead) {
      return await apiCoreFetch('Leads/adlead', {
        method: 'POST',
        body: data,
      })
    },
    async submitFacilitiesLead(data: Lead) {
      return await apiCoreFetch('Leads/facilitylead', {
        method: 'POST',
        body: data,
      })
    },
    async submitEventLead(data: Lead, eventId: number | string) {
      return await apiCoreFetch(`Leads/eventLead/${eventId}`, {
        method: 'POST',
        body: data,
      })
    },
    async trialLead(data: TrialSignUpLead) {
      return await apiCoreFetch('Leads/triallead', {
        method: 'POST',
        body: data,
      })
    },
  }
}
