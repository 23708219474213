import type { House } from '~/typesAuto/apicore/v1'

export default () => {
  const { apiCoreFetch } = useApiFetch()
  return {
    // used to get resolve subject and theme labels
    async get(id: string): Promise<House> {
      return await apiCoreFetch<House>(`Houses/${id}`)
    },
  }
}
