import fallbackImageUrl from '~/assets/images/fallback_image.svg?url'
import type { Election } from '~/typesManual/vaa_api/election'

export default function formatCandidateImageUrl(
  imageFileName: string | undefined,
  electionPrefix: Election['Prefix'],
  baseUrl?: string
) {
  if (!imageFileName) return fallbackImageUrl

  return new URL(
    electionPrefix
      ? `/images/kandidatdatabase/${electionPrefix}/kvadrat-${imageFileName}`
      : `/images/kandidatdatabase/kvadrat-${imageFileName}`,
    baseUrl
  ).href
}
