import type { FetchOptions } from 'ofetch'

export const useSentry = () => {
  const nuxtApp = useNuxtApp()

  const sentry = import.meta.server
    ? useRequestEvent()?.context.$sentry // Server-side
    : nuxtApp.$sentry // Client-side

  // Create function for logging fetch timeout errors
  type OnRequestError = FetchOptions['onRequestError']
  const logTimeout: OnRequestError = ({ error, request }) => {
    if (error.name !== 'AbortError') {
      return
    }

    const fetchUrl = typeof request === 'string' ? request : request.url

    console.error(`Timeout when requesting ${fetchUrl}`)

    sentry?.setTag('fetch_url', fetchUrl)
    sentry?.captureException(error)
  }

  return {
    sentry,
    logTimeout,
  }
}
