// Server: Inject runtime override from the request context into the Nuxt app so it can be accessed via the `useRuntimeOverride` composable.
// Client: Add a function to easily set the runtime override cookie and reload the page.

import { RuntimeOverride } from '~/typesManual/runtimeOverride'

export default defineNuxtPlugin({
  name: 'injectRuntimeOverride',
  enforce: 'pre',
  setup: () => {
    if (import.meta.server) {
      // Inject runtime override from cookie on server
      const event = useRequestEvent()

      if (!event?.context.runtimeOverride) return

      useRuntimeOverride().value = event.context.runtimeOverride
    } else {
      // Inject function on client to easily set runtime override
      window.setRuntimeOverride = (runtimeOverride: RuntimeOverride) => {
        document.cookie = `runtimeOverride=${encodeURIComponent(
          JSON.stringify(runtimeOverride)
        )}`
        window.location.reload()
      }
    }
  },
})

declare const window: {
  setRuntimeOverride: (runtimeOverride: RuntimeOverride) => void
} & Window
