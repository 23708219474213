<template>
  <div v-if="showTools" class="fixed right-10 bottom-0 z-[1000]">
    <PopoverRoot>
      <PopoverTrigger
        class="p-2 w-64 bg-white rounded-t border border-b-0 border-gray-300 flex items-center"
      >
        <img src="/A_Logomark.svg" alt="Altinget logo" class="w-4 h-4 mr-1.5" />
        <span class="text-sm font-sans">Alrow Tools</span>
        <div class="ml-auto flex items-center gap-2">
          <div
            class="block w-3 h-3 rounded-full bg-[#f97316]"
            title="Runtime override enabled"
          />
          <CommonFontAwesomeIcon
            class="text-gray-500 text-sm ml-auto"
            icon="fa-regular fa-chevron-up"
          />
        </div>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          side="top"
          class="bg-white p-2 w-64 rounded border border-gray-300 z-[1000]"
        >
          <div class="flex flex-col font-sans">
            <div v-if="runtimeOverride">
              <div class="flex items-center gap-1 mb-1">
                <p class="font-bold mr-auto">Runtime override</p>
                <button
                  class="shrink-0"
                  title="Click to remove all overrides"
                  @click="removeOverride()"
                >
                  <CommonFontAwesomeIcon
                    class="text-gray-500 text-sm ml-auto"
                    icon="fa-regular fa-trash"
                  />
                </button>
              </div>
              <ul class="flex flex-col gap-2">
                <li v-for="(value, key) in runtimeOverride">
                  <p class="text-xs font-bold">{{ key }}</p>
                  <div class="flex items-center gap-1">
                    <p class="truncate text-gray-800 mr-auto" :title="value">
                      {{ value }}
                    </p>
                    <button
                      class="shrink-0"
                      title="Click to copy value"
                      @click="copy(value || '')"
                    >
                      <CommonFontAwesomeIcon
                        class="text-gray-500 text-sm ml-auto"
                        icon="fa-regular fa-copy"
                      />
                    </button>
                    <button
                      class="shrink-0"
                      title="Click to remove override"
                      @click="removeOverride(key)"
                    >
                      <CommonFontAwesomeIcon
                        class="text-gray-500 text-sm ml-auto"
                        icon="fa-regular fa-trash"
                      />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <PopoverClose />
          <PopoverArrow />
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  </div>
</template>

<script setup lang="ts">
import {
  PopoverArrow,
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from 'radix-vue'
import { RuntimeOverrideKey } from '~/typesManual/runtimeOverride'

const alrowToolsCookie = useCookie<boolean>('alrowTools')
const runtimeOverride = useRuntimeOverride()

const showTools = computed(
  () => alrowToolsCookie.value || runtimeOverride.value
)

const copy = (value: string) => {
  navigator.clipboard.writeText(value)
}

const removeOverride = (key?: RuntimeOverrideKey) => {
  if (!runtimeOverride.value) return

  if (!key || Object.keys(runtimeOverride.value).length === 1) {
    // Clear cookie if no key or last key
    document.cookie = 'runtimeOverride=; max-age=0'
  } else {
    // Delete key from cookie
    document.cookie = `runtimeOverride=${JSON.stringify(
      Object.fromEntries(
        Object.entries(runtimeOverride.value).filter(([k]) => k !== key)
      )
    )}`
  }

  // Reload page to apply changes
  return navigateTo(window.location.href, { external: true })
}
</script>
