import { decodePath } from 'ufo'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const indexStore = useIndexStore()

  const isFirstPageLoad = decodePath(to.fullPath) === decodePath(from.fullPath)
  const hasPaperChanged = to.params.paper !== from.params.paper

  if (isFirstPageLoad || hasPaperChanged) {
    indexStore.setCurrentPaper(to.params.paper?.toString())
  }
})
