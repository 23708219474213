import type { FetchOptions, FetchContext } from 'ofetch'
import { withBase, withQuery } from 'ufo'
import { sha256 } from 'ohash'

const TIMEOUT_LIMIT = 10000

export const useApiFetch = () => {
  const runtimeConfig = useRuntimeConfig()
  const siteConfig = useSiteConfig()
  const { logTimeout } = useSentry()

  const PUBLIC_API_TOKEN = 'b401f511-f4b3-46af-89b8-befaa1a586bf'

  const accessToken = useAccessToken()
  const autologin = useLegacyAutoLogin()

  const logFetch = ({ request, options, response }: FetchContext) => {
    if (!import.meta.server) return
    if (!runtimeConfig.logFetch) return

    // Add base URL and query parameters to the request URL in onResponse
    // Copied from ofetch
    if (typeof request === 'string') {
      if (options.baseURL) {
        request = withBase(request, options.baseURL)
      }
      if (options.query || options.params) {
        request = withQuery(request, {
          ...options.params,
          ...options.query,
        })
      }
    }

    const type = response ? 'res' : 'req'
    const url = typeof request === 'string' ? request : request.url
    const hash = sha256(url).slice(0, 7)

    console.log(`[${type}] (${hash}) ${url}`)
  }

  const apiFetch = $fetch.create({
    baseURL: `https://${siteConfig.apiurl}/mailcontentapi/v1/`,
    onRequest(context) {
      // Remove credentials on the server since Cloudflare Workers do not support credentials and they're only used for client-side requests
      stripFetchCredentialsOnServer(context.options)

      // Add authorization header to all requests. Done here to avoid headers being overwritten by individual requests.
      context.options.headers = {
        ...context.options.headers,
        authorization: PUBLIC_API_TOKEN,
      }

      logFetch(context)
    },
    timeout: TIMEOUT_LIMIT,
    retry: 0,
    onRequestError: logTimeout,
    onResponse: logFetch,
  })

  const jobshotApiFetch = $fetch.create({
    baseURL: `https://${siteConfig.jobshoturl}`,
    onRequest(context) {
      // Remove credentials on the server since Cloudflare Workers do not support credentials and they're only used for client-side requests
      stripFetchCredentialsOnServer(context.options)
      logFetch(context)
    },
    timeout: TIMEOUT_LIMIT,
    retry: 0,
    onRequestError: logTimeout,
    onResponse: logFetch,
  })

  const vaaApiFetch = $fetch.create({
    baseURL: `https://${siteConfig.apiurl}/vaa-api/`,
    onRequest(context) {
      if (!siteConfig.vaaApiKey) return
      // Remove credentials on the server since Cloudflare Workers do not support credentials and they're only used for client-side requests
      stripFetchCredentialsOnServer(context.options)

      context.options.headers = {
        ...context.options.headers,
        authorization: siteConfig.vaaApiKey,
      }

      logFetch(context)
    },
    onResponse: logFetch,
  })

  const identityApiFetch = $fetch.create({
    baseURL: `https://${siteConfig.identityBaseUrl}/api/`,
    onRequest: async (context) => {
      stripFetchCredentialsOnServer(context.options)
      logFetch(context)
    },

    timeout: TIMEOUT_LIMIT,
    retry: 0,
    onRequestError: logTimeout,
  })

  const apiCoreFetch = $fetch.create({
    baseURL: `https://${siteConfig.apicoreurl}/api/`,
    onRequest: async (context) => {
      stripFetchCredentialsOnServer(context.options)

      if (accessToken.token.value && !accessToken.isValid()) {
        await accessToken.refresh()
      }

      if (accessToken.token.value) {
        context.options.headers = {
          ...context.options.headers,
          authorization: `Bearer ${accessToken.token.value}`,
        }
        return
      }

      if (autologin.token.value) {
        context.options.headers = {
          ...context.options.headers,
          token: autologin.token.value,
          authorization: PUBLIC_API_TOKEN,
        }
        return
      } else {
        context.options.headers = {
          ...context.options.headers,
          authorization: PUBLIC_API_TOKEN,
        }
      }
      logFetch(context)
    },
    timeout: TIMEOUT_LIMIT,
    retry: 0,
    onRequestError: logTimeout,
  })

  return {
    apiFetch,
    vaaApiFetch,
    jobshotApiFetch,
    identityApiFetch,
    apiCoreFetch,
  }
}

function stripFetchCredentialsOnServer(options: FetchOptions) {
  if (import.meta.server && options.credentials) {
    options.credentials = undefined
  }
}
