export function useLegacyAutoLogin() {
  const newAutoLogin = useNewAutoLogin()

  const tokenCookie = useCookie('autologin', {
    httpOnly: false,
    secure: false,
    sameSite: 'lax',
    path: '/',
  })

  const token = computed(() => {
    if (import.meta.server && newAutoLogin.value !== undefined) {
      return newAutoLogin.value || undefined
    }

    return tokenCookie.value
  })

  return {
    token,
  }
}

/**
 * On the server the cookie is only read at the start of the request, so we need a way to keep track of updates during the current SSR response.
 */
export function useNewAutoLogin() {
  const event = useRequestEvent()

  if (import.meta.client || !event) {
    return ref<string | null | undefined>()
  }

  if (!('newAutoLogin' in event.context)) {
    event.context.newAutoLogin = ref<string | null | undefined>()
  }

  return event.context.newAutoLogin
}
